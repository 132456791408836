.service-single-section .service-single-content p {
    font-size: 14px !important;
}

.service-single-section .service-single-content h2 {
    font-size: 25px !important;
}

.service-single-section .service-single-content ul > li li {
    list-style-type: square;
}

.service-single-section .service-single-content ul > li li li {
    list-style-type: circle;
}

.service-single-section .service-single-content div > p {
    font-size: 20px !important;
}