.title-container {
    margin: 50px;
    text-align: center;
}

.entry-details h5 {
    font-family: "Roboto Slab", serif;
    font-weight: 400;
    color: #c8a56a;
}

@media(min-width: 911px) {
    .entry-details h3 {
        height: 56px;
    }
}

@media(min-width: 911px){
    .entry-details p {
        height: 136px;
    }
}

.section-title a {
    color: #c8a56a;
}

.section-title a:hover {
    color: #b99c69;
}