#blog-image-thumbnail {
    width: 100%;
    height: 250px;
    object-fit: cover;
    margin-top: 35px;
}

@media(max-width: 991px) {
    #blog-image-thumbnail {
        width: 300px;
        height: 188px;
        object-fit: cover;
    }
}