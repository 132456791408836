.site-footer .upper-footer {
    background-color: #c8a56a;
}

.about-widget p {
    color: #ffff;
}

.site-footer p, .site-footer a {
    color: #ffffff !important;
}

.site-footer .contact-map iframe {
    height: 280px;
}