.popup {
    background-color: #c8a56a;
    font-family: "Roboto Slab", serif;
    margin: 15px;
    padding: 15px;
    border-radius: 15px;
}

.popup h2 {
    text-align: center;
    margin: 3px;
}

.popup-inner {
    text-align: center;
}

.popup .popup-inner .theme-btn {
    display: inline-flex;
    font-weight: 500;
    background-color: white;
    color: #c8a56a;
    border-radius: 10px;
}