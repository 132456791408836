#blog-image {
    height: 300px;
    width: 940px;
    object-fit: cover;
}

@media(max-width: 991px) {
    #blog-image {
        width: 300px;
        height: 188px;
        object-fit: cover;
    }
}