#blogTitle {
    margin-left: 15px;
}

/* #blog-container {
    border: 2px solid black;
    padding-top: 15px;
    padding-bottom: 15px;
} */

#blogDesc {
    height: 6em;
    width: 25em;
    line-height: 1.8em;
    text-overflow: clip;
    word-wrap: break-word;
}