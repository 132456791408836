.swiper-button-prev,
.swiper-button-next {
color: grey;
width: 30px;
height: 30px;
line-height: 60px;
margin-top: -30px;
text-align: center;
border-radius: 60px;
}

#aktuality {
    background: none;
    min-height: 0px;
}