.theme-btn, .theme-btn-s3 {
    min-width: 345px;
}

@media (min-width: 1200px) {
    .container {
        width: 1500px !important;
    }
}

@media (min-width: 1200px) and (max-width: 1650px) {
    #cta-section-container {
        width: 900px !important;
    }
}

@media (min-width: 1600px) {
    #cta-section-container {
        width: 1500px;
    }
}

@media (max-width: 767px){
    .theme-btn-s3 {
        width: 230px;
    }
}

@media (max-width: 767px){
    .theme-btn {
        width: 230px;
    }
}