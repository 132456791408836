.feature-grid {
    height: 652px;
  }

.read-more {
    font-family: "Roboto Slab", serif;
    color: #687693;
    padding-left: 50px;
    position: absolute;
    bottom: 25px;
    right: 50px;
  }

.section-title {
    color: #c8a56a;
    font-family: "Roboto Slab", serif;
    font-weight: 500;
  }

.title-container {
  margin-bottom: 125px;
}

.feature-grid p a {
  all: unset;
  cursor: pointer;
}