#obcianske-pravo {
    background: url("../assets/cibenka/civil-law-banner.jpg") center no-repeat local !important;
    background-size: 1140px !important;
    min-width: 430px;
}

@media(max-width: 991px) {
    #obcianske-pravo {
        background-size: auto;
        min-width: none;
    }
}