@media (max-width: 911px){
    .hero-style-2 .container {
        position: relative;
        bottom: 175px;
    }
}

@media (min-width: 911px){
    .hero-style-2 .container {
        position: relative;
        right: 600px;
        /* padding-left: 450px !important; */
    }
}

.slide-btns .theme-btn {
    background-color: transparent;
    border: 2px solid #fff;
}

.hero-slider .swiper-container, .hero-slider .hero-container {
    height: 88% !important;
}

/* .hero-style-2 .slide-btns, .hero-style-2 .slide-text p, .hero-style-2 .slide-title {
    margin-right: 10vw !important;
} */