#kontakt {
    background: url("../assets/cibenka/cibenka-banner.jpg") center no-repeat local !important;
    background-size: 1170px !important;
    background-position: 50% 50px !important;
}

.section-title-s2 {
    margin-bottom: 0;
}

.section-title-s2 > span {
    padding-top: 20px;
}

.contact-section-s3 .contact-info-col {
    padding-top: 0;
}

#kontakt .container {
    padding-top: 200px;
}

.contact-info a {
    color: #687693;
    text-decoration: underline;
}
.contact-form-header {
    padding-left: 80px;
}

@media (min-width: 911px) {
    .contact-form {
        width: 650px;
    }
}

@media (max-width: 900px) {
    .contact-form {
        width: 400px;
    }
}
