#contact-info {
    color: #fff !important;
}

#contact-info a {
    color: #fff;
}

.site-header #navbar > ul .sub-menu {
    text-transform: none !important;
}